import { applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../app/rootReducer'; 
import { accountSlice } from '../features/accounts/accountSlice';

export const store = configureStore(
  {
     reducer:rootReducer, 
     middleware: (getDefaultMiddleware) =>  getDefaultMiddleware().concat(accountSlice.middleware) },
     applyMiddleware(
        thunkMiddleware 
     )
);