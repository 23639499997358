import jwt_decode from "jwt-decode";
import { store } from '../app/store'
import { baseApi } from '../app/baseApi'

const token = (JSON.parse(localStorage.getItem('user'))?.token) ? jwt_decode(JSON.parse(localStorage.getItem('user'))?.token) : null;
 
const user_id = (token) ? token.sub : null;

export function isLoggedIn() {
	return !!localStorage.getItem('user')
}
 
export function getUserId() {
	return user_id
}

export function clearGlobalState() {
	store.dispatch(baseApi.util.resetApiState())
	return true
}

export function currentUser() {
	const token = (JSON.parse(localStorage.getItem('user'))?.token) ? jwt_decode(JSON.parse(localStorage.getItem('user'))?.token) : null;
	return (token?.sub) ? token?.sub : null
}

export function isJWTExpired() {
	if (token) { 		 
		if (token.exp * 1000 <= new Date().getTime()) {
			return true;
		}
	}
	return false;
}

export function isJsonString(str) { 
    try {
		 JSON.parse(JSON.stringify(str));		
    } catch (e) { 
        return false;
    }
    return true;
}

/* Permission Chips */ 
