import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'  
import { BehaviorSubject } from 'rxjs'; 

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')));

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_ADMIN_SERVICE_HOST}`,  prepareHeaders: (headers) => {
 
    let token ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwMDEvYXBpL2xvZ2luIiwiaWF0IjoxNzE2MzczNzE1LCJleHAiOjE3MTYzNzczMTUsIm5iZiI6MTcxNjM3MzcxNSwianRpIjoiTnZ4TElBRVdtY3N5bG5zMCIsInN1YiI6IjciLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.ReILT8dyhQMEUkSFMY5bdwrT_KcIENnO19wqX8Dz8Zg'
    if(currentUserSubject.value === null){
       token = JSON.parse(localStorage.getItem('user'))?.token 
    }
    
    if (currentUserSubject.value?.token) {    
       token =  currentUserSubject.value.token;       
    } 
    headers.set('authorization', `Bearer ${token}`)
   
    return headers
  }}),
  tagTypes: ['Account','Users','Internships','Jobs','Candidates'],
  endpoints: () => ({}),
});