import React from 'react'
import logo from '../assets/svg/logo.svg'
function ThemedSuspense() {
  return (
    <div className="grid justify-items-center items-center h-96">
      <div>
        <h3 className='text-center'>Loading...</h3>
        <img src={logo}  className="w-28" alt='Please wait'/>
      </div>
    </div>
  )
}

export default ThemedSuspense
