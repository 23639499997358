import React, { useEffect } from 'react'
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import { isJWTExpired} from '../helpers';
import toast, { Toaster } from 'react-hot-toast';
import { baseApi } from '../app/baseApi'
import { store } from '../app/store'

export default function PrivateRoute() {
    let isLoggedIn = localStorage.getItem("user") == null ? false : true;

    const navigate = useNavigate();

    useEffect(() => {  
      
        if (isJWTExpired()) { 
            toast.error('Your session has expired. Please relogin')
            store.dispatch(baseApi.util.resetApiState());
            localStorage.removeItem('user');
            navigate('/'); 
        }
    }, [isJWTExpired()]);
    
    return (
        <>
            <Toaster />
            {isLoggedIn ? <Outlet /> : <Navigate to="/" />};
        </>

    )

}